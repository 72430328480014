import Fuse from "fuse.js";
class {
    onCreate(input) {
        this.state = {
            suggestions: [],
            fuse: new Fuse(input.items, {
                keys: ["name"],
                threshold: 0.6,
                distance: 100,
            }),
            debounceTimer: null,
            searchText: "",
        };
    }

    inputChanged(el) {
        this.state.searchText = el.target.value;
        if (this.state.debounceTimer) {
            clearTimeout(this.state.debounceTimer);
        }
        this.state.debounceTimer = setTimeout(() => {
            console.time("fuse");
            this.state.suggestions = this.state.fuse
                .search(el.target.value, { limit: 15 });
            console.timeEnd("fuse");
        }, 100);
    }
}

<label for="search">Search CS2 items</label>
<input#search
    type="text"
    autocomplete="off"
    on-input("inputChanged")
    value=state.searchText
    aria-label="Enter search term"
    name=input.name
    placeholder="Breakout Weapon Case"
>
<ul style={ height: state.suggestions.length * 30 + 'px' }>
    <for|row, index| of=state.suggestions>
        <li key=(row.item.id)>
            <a href=("/item/" + row.item.name)>${row.item.name}</a>
        </li>
    </for>
</ul>
style {
    :root {
        --input-width: min(400px, 100%);
    }
    label {
        width: var(--input-width);
        margin-top:3rem;
        padding: 10px 0;
    }
    input#search {
        width: var(--input-width);
        box-sizing: border-box;
        font-size: 24px;
        margin: 0 0 1rem 0;
        padding: 10px 20px;
        border: 2px solid var(--dark-color);
        outline: none;
        transition: all 0.2s ease-in-out;
    }
    input:focus,
    input:hover,
    input:active {
        border: 2px solid var(--light-color);
    }
    ul {
        width: var(--input-width);
        box-sizing: border-box;
        list-style: none;
        margin: 0;
        padding: 0;
        opacity: 1;
        transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out;
    }
    ul:empty {
        opacity: 0;
    }
    li {
        border-left: 2px solid var(--dark-color);
        border-right: 2px solid var(--dark-color);
        padding: 4px 0.5rem;
    }
    li:first-child {
        border-top: 2px solid var(--dark-color);
    }
    li:last-child {
        border-bottom: 2px solid var(--dark-color);
    }
    li a {
        color: var(--dark-color);
        text-decoration: none;
    }
    li a:hover {
        text-decoration: underline;
    }

    @media (max-width: 800px) {
        label, input#search {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
